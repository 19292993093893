* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-margin-top: 4.5rem;
}

.html {
  scroll-behavior: smooth;
}

.button {
  color: #fff;
  background-color: #ee0062;
  border-radius: .25rem;
  padding: .5rem .75rem;
  font-weight: bold;
  display: inline-block;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

@media (width >= 768px) {
  .button {
    padding: .7rem .9rem;
  }
}

@media (width >= 1440px) {
  .button {
    padding: .8rem 1rem;
  }
}

.button__img {
  width: 1.25rem;
  height: 1.25rem;
}

@media (width >= 768px) {
  .button__img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (width >= 1440px) {
  .button__img {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.footer {
  color: #fff;
  background-color: #ee0062;
  max-width: 100%;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (width >= 768px) {
  .footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (width >= 1024px) {
  .footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.footer__div {
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: 1rem;
  padding: 1rem;
  display: grid;
}

@media (width >= 768px) {
  .footer__div {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    max-width: 800px;
    margin: auto;
  }
}

.footer__div--map {
  border: none;
}

.footer__flex {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.footer__iframe {
  border: none;
  width: 270px;
  height: 270px;
}

@media (width >= 768px) {
  .footer__iframe {
    width: 350px;
  }
}

.footer__img {
  width: 40px;
  height: auto;
}

.footer__header {
  text-align: center;
  padding-bottom: 1.5rem;
  font-weight: 500;
}

@media (width <= 767px) {
  .footer__header {
    padding-bottom: 1rem;
    font-size: 24px;
  }
}

.footer__header--2 {
  text-align: center;
  font-weight: 500;
}

@media (width <= 767px) {
  .footer__header--2 {
    font-size: 24px;
  }
}

.footer__paragraph {
  margin: 8px 0;
  font-size: 20px;
}

@media (width >= 768px) {
  .footer__paragraph {
    font-size: 24px;
  }
}

.hamburger {
  z-index: 20;
  background-color: #ee0062;
  border: none;
  outline: none;
  padding: 4px;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

@media screen and (width >= 768px) {
  .hamburger {
    display: none;
  }
}

.hamburger__span {
  background-color: #fff;
  width: 24px;
  height: 2px;
  transition: transform .3s ease-in-out;
  display: block;
}

.hamburger__div {
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
  margin-bottom: .375rem;
  display: flex;
}

.header {
  background-color: #ee0062;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  max-width: 100%;
  min-height: 50px;
  margin: 0;
  padding: .5rem;
  display: flex;
  position: sticky;
  top: 0;
}

.header__div {
  background-color: #ee0062;
  justify-content: start;
  align-items: center;
  display: flex;
}

.logo {
  width: 70%;
  max-width: 1024px;
  height: auto;
  margin: auto;
  display: block;
}

@media (width <= 630px) {
  .logo {
    padding-top: 24px;
  }
}

@media (width <= 470px) {
  .logo {
    padding-top: 32px;
  }
}

@media (width <= 380px) {
  .logo {
    padding-top: 40px;
  }
}

.main {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

.navigation {
  background-color: #ee0062;
  display: none;
}

@media (width >= 768px) {
  .navigation {
    background-color: #ee0062;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: auto;
    display: flex;
  }
}

.navigation__ul {
  z-index: 10;
  text-transform: uppercase;
  background-color: #ee0062;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  font-weight: 500;
  list-style-type: none;
}

@media (width >= 768px) {
  .navigation__ul {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 8px 20px;
    font-size: 16px;
    display: flex;
  }
}

@media (width >= 1028px) {
  .navigation__ul {
    font-size: 24px;
  }
}

.navigation__ul a {
  text-underline-offset: none;
  color: #fff;
  text-decoration: none;
}

.navigation--open {
  background-color: #ee0062;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 3rem;
  left: 0;
}

.navigation--open .navigation__ul {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.section__header {
  text-align: center;
  text-transform: uppercase;
  color: #ee0062;
  margin: 8px 0;
  font-size: 24px;
  font-weight: bold;
}

@media (width >= 768px) {
  .section__header {
    margin: 16px 0;
    font-size: 28px;
  }
}

@media (width >= 1024px) {
  .section__header {
    margin: 24px 0;
    font-size: 32px;
  }
}

.section__offer {
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: 1rem;
  padding: 1rem;
  display: grid;
}

@media (width >= 768px) {
  .section__offer {
    grid-template-columns: 1fr 1fr;
    place-items: start stretch;
    column-gap: 3rem;
    max-width: 1480px;
    margin: auto;
    padding-bottom: 4rem;
  }
}

@media (width >= 1024px) {
  .section__offer {
    column-gap: 6rem;
    padding-bottom: 6rem;
  }
}

.section__img {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.section__paragraph {
  text-align: justify;
  align-items: center;
  max-width: 1480px;
  margin: auto;
  padding-bottom: 1.5rem;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
}

@media (width >= 768px) {
  .section__paragraph {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 20px;
  }
}

@media (width >= 1024px) {
  .section__paragraph {
    font-size: 24px;
  }
}

.table__thead {
  color: #e52058;
  text-transform: uppercase;
  text-align: left;
  font-size: 16px;
}

@media (width >= 768px) {
  .table__thead {
    font-size: 20px;
  }
}

.table__thead--row {
  padding-top: 24px;
  padding-bottom: 8px;
}

@media (width >= 1024px) {
  .table__thead--row {
    font-size: 24px;
  }
}

.table__price {
  text-align: right;
  padding-left: 16px;
  font-weight: bold;
}

.tbody__row {
  font-size: 16px;
}

@media (width >= 1024px) {
  .tbody__row {
    font-size: 18px;
  }
}

.tbody__row--special {
  padding-top: 16px;
  font-weight: bold;
}

.td__span {
  font-size: 80%;
}

th:first-child {
  min-width: 280px;
}

@media (width <= 768px) {
  th:first-child {
    min-width: 200px;
  }
}

/*# sourceMappingURL=index.01dee456.css.map */
