* {
    scroll-margin-top: 4.5rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.html {
    scroll-behavior: smooth;
}

.button {
    color: #ffffff;
    font-weight: bold;
    padding: 0.5rem 0.75rem;
    background-color:#ee0062;
    border-radius: 0.25rem;
    display: inline-block;
    position: fixed;
    right: 1rem;
    bottom: 1rem;

    @media (min-width: 768px) {
        padding: 0.7rem 0.9rem;
    }

    @media (min-width: 1440px) {
        padding: 0.8rem 1rem;
    }
}

.button__img {
    width: 1.25rem;
    height: 1.25rem;

    @media (min-width: 768px) {
        width: 1.5rem;
        height: 1.5rem;
    }

    @media (min-width: 1440px) {
        width: 1.75rem;
        height: 1.75rem;
    }
}

.footer {
    max-width: 100%;
    background-color:#ee0062;
    height: auto;
    color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media (min-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    @media (min-width: 1024px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.footer__div {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 1rem;
    padding: 1rem;
    
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        max-width: 800px;
        margin: auto;
    }
}

.footer__div--map {
    border: none;
}

.footer__flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.footer__iframe {
    height: 270px;
    border: none;
    width: 270px;

    @media (min-width: 768px) {
        width: 350px;
    }
}

.footer__img {
    width: 40px;
    height: auto;
}

.footer__header {
    text-align: center;
    font-weight: 500;
    padding-bottom: 1.5rem;

    @media (max-width: 767px) {
        font-size: 24px;
        padding-bottom: 1rem;
    }
}

.footer__header--2 {
    text-align: center;
    font-weight: 500;

    @media (max-width: 767px) {
        font-size: 24px;
    }
}

.footer__paragraph {
    font-size: 20px;
    margin: 8px 0;

    @media (min-width: 768px) {
        font-size: 24px;
    }
}

.hamburger {
    background-color: #ee0062;
    border: none;
    outline: none;
    padding: 4px;
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 20;
}

@media screen and (min-width: 768px) {
    .hamburger {
        display: none;
    }
}

.hamburger__span {
    display: block;
    width: 24px;
    height: 2px;
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out;
}

.hamburger__div {
    margin-bottom: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 4px;
}

.header {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0.5rem;
    background-color: #ee0062;
    position: sticky;
    top: 0;
    max-width: 100%;
    margin: 0
}

.header__div {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #ee0062;
}

.logo {
    width: 70%;
    max-width: 1024px;
    margin: auto;
    display: block;
    height: auto;
  
    @media (max-width: 630px) {
        padding-top: 24px;
    }

    @media (max-width: 470px) {
        padding-top: 32px;
    }

    @media (max-width: 380px) {
        padding-top: 40px;
    }
}

.main {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

.navigation {
    background-color: #ee0062;
    display: none;
}

@media (min-width: 768px) {
    .navigation {
        background-color: #ee0062;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin: auto;
    }
}

.navigation__ul {
    width: 100%;
    z-index: 10;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 0;
    list-style-type: none;
    background-color: #ee0062;
    margin: 0;

    @media (min-width: 768px) {
        font-size: 16px;
        padding: 8px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

    @media (min-width: 1028px) {
        font-size: 24px;
    }
}

.navigation__ul a {
    text-decoration: none;
    text-underline-offset: none;
    color: #ffffff;
}

.navigation--open {
    background-color: #ee0062;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 3rem;
    left: 0;
}

.navigation--open .navigation__ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.section__header {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    color: #ee0062;
    font-weight: bold;
    margin: 8px 0;

    @media (min-width: 768px) {
        font-size: 28px;
        margin: 16px 0;
    }

    @media (min-width: 1024px) {
        font-size: 32px;
        margin: 24px 0;
    }
}

.section__offer {
    padding: 1rem;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 1rem;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        align-items: start;
        column-gap: 3rem;
        max-width: 1480px;
        margin: auto;
        justify-items: stretch;
        padding-bottom: 4rem;
    }

    @media (min-width: 1024px) {
        column-gap: 6rem;
        padding-bottom: 6rem;
    }
}

.section__img {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-bottom: 2rem;
}

.section__paragraph {
    font-size: 16px;
    text-align: justify;
    max-width: 1480px;
    margin: auto;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    padding-bottom: 1.5rem;

    @media (min-width: 768px) {
        font-size: 20px;
        padding-left: 16px;
        padding-right: 16px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
}

.table__thead {
    color:#e52058;
    text-transform: uppercase;
    font-size: 16px;
    text-align: left;

    @media (min-width: 768px) {
        font-size: 20px;
    }

}

.table__thead--row {
    padding-bottom: 8px;
    padding-top: 24px;

    @media (min-width: 1024px) {
        font-size: 24px;
    }
}

.table__price {
    font-weight: bold;
    padding-left: 16px;
    text-align: right;
}

.tbody__row {
        font-size: 16px;

    @media (min-width: 1024px) {
        font-size: 18px;
    }

}

.tbody__row--special {
    font-weight: bold;
    padding-top: 16px;
}

.td__span {
    font-size: 80%;
}

th:first-child {
    min-width: 280px;

    @media (max-width: 768px) {
        min-width: 200px;
    }
}
